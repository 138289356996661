import { Grid } from '@chakra-ui/react';
import React from 'react';
import Footer from './Footer';

interface Props {
  navbar?: React.ReactNode;
  showFooter?: boolean;
  showNavbar?: boolean;
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
  navbar,
  showFooter = true,
  showNavbar = true,
  children,
}) => {
  return (
    <Grid templateRows="auto 1fr auto" minHeight="100vh" maxHeight="100vh">
      {showNavbar && navbar}

      {children}

      {showFooter && <Footer />}
    </Grid>
  );
};
