import { useBoolean, useMediaQuery } from '@chakra-ui/react';
import { GameNavbar } from 'components/ui/GameNavbar';
import { WorldMap } from 'components/ui/WorldMap';
import { GameEndedModal } from 'components/ui/modals/GameEnded';
import { NextCityModal } from 'components/ui/modals/NextCity';
import { NextLevelModal } from 'components/ui/modals/NextLevel';
import { SmallScreenModal } from 'components/ui/modals/SmallScreenModal';
import { StartGameModal } from 'components/ui/modals/StartGame';
import React, { useEffect, useState } from 'react';
import { GameState } from 'types/GameState';
import { ScreenPoint } from 'types/Point';
import { CountryCode, getCountryName } from 'utils/iso2.mapper';

interface Props {
  secondsPassed: number;
  gameState: GameState;
  correctLocation?: ScreenPoint;
  pointsToPass: number;
  currentLevel: number;
  currentLocation: number;
  levelScore: number;
  totalScore: number;
  locationScore: number;
  totalLevelLocations: number;
  totalLevels: number;
  cityName: string;
  countryCode: CountryCode;
  scorePerCity: number;
  nextLevelLocations: number;
  nextLevelPointsToPass: number;
  distance: number;
  nickname: string;
  guest: boolean;
  levelTime: number;
  mapIq: number;
  changeGameState: (state: GameState) => void;
  onMapClickCallback: (point: ScreenPoint) => void;
  startNextLevel: () => void;
  startNextCity: () => void;
  getTotalCities: (l?: number) => number;
  endGame: () => void;
}

export const GameView: React.FC<Props> = ({
  secondsPassed,
  gameState,
  correctLocation,
  currentLevel,
  currentLocation,
  levelScore,
  totalScore,
  locationScore,
  totalLevelLocations,
  totalLevels,
  pointsToPass,
  nextLevelLocations,
  nextLevelPointsToPass,
  changeGameState,
  onMapClickCallback,
  startNextLevel,
  startNextCity,
  getTotalCities,
  endGame,
  cityName,
  countryCode,
  scorePerCity,
  distance,
  nickname,
  guest,
  levelTime,
  mapIq,
}) => {
  const [clickedLocation, setClickedLocation] = useState<ScreenPoint>();
  const [mapLoaded, setMapLoaded] = useBoolean(false);

  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  useEffect(() => {
    if ([GameState.Paused, GameState.LeveledUp].includes(gameState)) {
      setClickedLocation(undefined);
    }
  }, [gameState]);

  const onMapClick = (point: ScreenPoint) => {
    if (clickedLocation || gameState !== GameState.Started) return;

    setClickedLocation(point);
    onMapClickCallback(point);
    changeGameState(GameState.ShowCorrectLocation);
  };

  return (
    <>
      {!isLargerThan1024 ? (
        <SmallScreenModal open />
      ) : (
        <>
          <GameNavbar
            secondsPassed={secondsPassed}
            levelTime={levelTime}
            totalPoints={totalScore}
            levelPoints={levelScore}
            toPass={pointsToPass}
            locationLabel={`${cityName}, ${getCountryName(countryCode)}`}
            countryCode={countryCode}
            level={currentLevel}
            totalLevels={totalLevels}
            locationIndex={currentLocation}
            disabled={[GameState.Loaded, GameState.Loading, GameState.LeveledUp].includes(
              gameState
            )}
            levelLocations={totalLevelLocations}
          />

          <WorldMap
            onClick={onMapClick}
            cityLocation={correctLocation}
            clickedLocation={clickedLocation}
            onMapLoaded={() => setMapLoaded.on()}
          />

          <StartGameModal
            loading={gameState === GameState.Loading || !mapLoaded}
            open={[GameState.Loading, GameState.Loaded].includes(gameState)}
            startGame={() => {
              changeGameState(GameState.Started);
            }}
            scoreToPass={pointsToPass}
            cities={totalLevelLocations}
            totalScore={totalLevelLocations * scorePerCity}
            nickname={nickname}
            levels={totalLevels}
            guest={guest}
          />

          {gameState === GameState.Paused && (
            <NextCityModal
              open={gameState === GameState.Paused}
              points={locationScore}
              location={`${cityName}, ${getCountryName(countryCode)}`}
              seconds={secondsPassed}
              pointsToAdvance={pointsToPass}
              levelPoints={levelScore}
              goNextCity={startNextCity}
              locationIndex={currentLocation + 1}
              totalLocations={totalLevelLocations}
              countryCode={countryCode}
              distance={distance}
              scorePerCity={scorePerCity}
              finishGame={endGame}
            />
          )}

          {gameState === GameState.LeveledUp && (
            <NextLevelModal
              totalLevels={totalLevels}
              open={gameState === GameState.LeveledUp}
              level={currentLevel + 1}
              points={levelScore}
              maxLevelPoints={totalLevelLocations * scorePerCity}
              pointsToAdvance={nextLevelPointsToPass}
              maxNextLevelPoints={nextLevelLocations * scorePerCity}
              totalPoints={totalScore}
              nextLevelCities={nextLevelLocations}
              nextLevel={startNextLevel}
              nickname={nickname}
              finishGame={endGame}
            />
          )}

          <GameEndedModal
            open={gameState === GameState.Ended}
            points={totalScore}
            maxPoints={getTotalCities() * scorePerCity}
            level={currentLevel + 1}
            cities={getTotalCities(currentLevel + 1)}
            mapIq={mapIq}
            nickname={nickname}
            totalLevels={totalLevels}
          />
        </>
      )}
    </>
  );
};
