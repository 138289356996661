import { Dimension, GeoPoint, ScreenPoint } from 'types/Point';

export const getMapDimension = (): Dimension => {
  return {
    width: window.innerWidth,
    height: window.innerHeight - 80,
  };
};

export const transformPoint = (p: ScreenPoint, a: Dimension, b: Dimension): ScreenPoint => {
  const x = ((a.height - p.y) * b.height) / a.height;
  const y = (p.x * b.width) / a.width;

  return {
    x: x,
    y: y,
  };
};

export const getGeoCoordinate = (point: ScreenPoint): GeoPoint => {
  let { x, y } = point;
  x += 12; // adjust for cursor offset
  y += 12; // adjust for cursor offset

  const { width, height } = getMapDimension();

  // Longitude per pixel
  const longitudePerPixel: number = 360 / width;
  // Latitude per pixel (note that y increases downwards, hence the negative sign)
  const latitudePerPixel: number = 180 / height;

  // Convert from screen coordinates to geographic coordinates
  const long: number = x * longitudePerPixel - 180;
  const lat: number = 90 - y * latitudePerPixel;

  return { lat: lat, long: long }; // adjust for cursor offset
};

export const normalizeGeoCoordinate = (point: GeoPoint): GeoPoint => {
  return {
    lat: point.lat + 90,
    long: point.long + 180,
  };
};

export const getScreenCoordinates = (point: GeoPoint, dim: Dimension): ScreenPoint => {
  const { long, lat } = point;
  const { width, height } = dim;

  const x = (long + 180) * (width / 360);

  // Latitude ranges from -90 to 90, invert the latitude value since the y-coordinate increases downwards
  const y = (90 - lat) * (height / 180);

  return { x, y };
};

const toRadians = (x: number): number => {
  return (x * Math.PI) / 180;
};

export const adjustDistance = (distance: number): number => {
  return distance / 1.1;
};

export const getScreenPointsDistance = (a?: ScreenPoint, b?: ScreenPoint) => {
  if (!a || !b) return 0;

  return getHaversineDistance(getGeoCoordinate(a), getGeoCoordinate(b));
};

export const getDistance = (a: GeoPoint, b: GeoPoint): number => {
  const dist = Math.hypot(Math.abs(a.long - b.long), Math.abs(a.lat - b.lat));
  return dist;
};

export const getHaversineDistance = (a?: GeoPoint, b?: GeoPoint): number => {
  if (!a || !b) return 0;

  const R = 6371;
  const dx = toRadians(Math.abs(a.long - b.long));
  const dy = toRadians(Math.abs(a.lat - b.lat));

  const t =
    Math.sin(dy / 2) * Math.sin(dy / 2) +
    Math.cos(toRadians(a.lat)) * Math.cos(toRadians(b.lat)) * Math.sin(dx / 2) * Math.sin(dx / 2);

  const d = 2 * Math.atan2(Math.sqrt(t), Math.sqrt(1 - t));
  return R * d;
};

export const getScreenDistance = (a: ScreenPoint, b: ScreenPoint): number => {
  const dist = Math.hypot(Math.abs(a.x - b.x), Math.abs(a.y - b.y));
  return dist;
};

export const getCircleCenterPoint = (a: ScreenPoint, b: ScreenPoint): ScreenPoint => {
  return {
    x: (a.x + b.x) / 2,
    y: (a.y + b.y) / 2,
  };
};

export const getPointFromMouseEvent = (e: React.MouseEvent, yOffset: number): ScreenPoint => {
  return {
    x: e.pageX,
    y: e.pageY - yOffset,
  };
};
