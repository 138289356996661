import {
  Box,
  Container,
  Flex,
  Grid,
  HStack,
  Select,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from '@chakra-ui/react';
import Avatar from 'boring-avatars';
import { DataTable, getNumformat, getSummary } from 'react-chakra-ui-table-v2';
import { FaTrophy } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { createColumnHelper } from '@tanstack/react-table';
import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { differenceInSeconds, format, formatDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { GameDto, GameMode } from 'types/api/game.dto';
import { BasicUserStatsDto, UserDto, UserStatsDto } from 'types/api/user.dto';
import { GameModeOptionCopyDto } from 'types/api/utils.dto';
import { useDataResource } from 'utils/api';
import { capitalize } from 'utils/strings';

const columnHelper = createColumnHelper<GameDto>();

export const ProfilePage = () => {
  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => info.row.index + 1,
      header: '#',
    }),
    columnHelper.accessor('started', {
      cell: (info) => format(new Date(info.getValue()), 'dd MMM yyyy'),
      header: 'Date',
    }),
    columnHelper.accessor('finished', {
      cell: ({ row }) =>
        formatDuration({
          seconds:
            differenceInSeconds(
              new Date(row.original.finished as string),
              new Date(row.original.started)
            ) % 60,
          minutes: Math.floor(
            differenceInSeconds(
              new Date(row.original.finished as string),
              new Date(row.original.started)
            ) / 60
          ),
        }),
      header: 'Duration',
    }),
    columnHelper.accessor('score', {
      cell: (info) => info.getValue(),
      header: 'Score',
      footer: ({ table }) => getNumformat(getSummary(table, 'score')),
    }),
    columnHelper.accessor('mapIq', {
      cell: (info) => info.getValue(),
      header: 'MapIQ',
    }),
  ];

  const navigate = useNavigate();
  const [historyMode, setHistoryMode] = useState<GameMode>(GameMode.CLASSIC);

  const { data: user, isLoading: userLoading } = useDataResource<UserDto>('/users/me', true);
  const { data: games } = useDataResource<GameDto[]>('/games/history', true, { mode: historyMode });
  const { data: stats } = useDataResource<UserStatsDto[]>('/users/stats', true);
  const { data: basicStats } = useDataResource<BasicUserStatsDto>('/users/basic-stats', true);

  const { data: options } = useDataResource<GameModeOptionCopyDto[]>(
    '/utils/game-modes-copy',
    false
  );

  useEffect(() => {
    document.title = 'MapIQ Dashboard - Track Your Progress and Stats';
  }, []);

  useEffect(() => {
    if (!userLoading && !user) {
      navigate('/login');
    }
  }, [userLoading, user, navigate]);

  return (
    <Layout navbar={<Navbar showUserMenu={user !== undefined} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        {userLoading ? (
          <Container centerContent h="full" alignItems="center" justifyContent="center">
            <Spinner size="xl" />
          </Container>
        ) : (
          <VStack p={[8, 8, 8, 16]} alignItems="flex-start" justifyContent="center">
            <Flex gap={4}>
              <Avatar
                size={100}
                name={user?.nickname}
                variant="beam"
                colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
              />

              <VStack alignItems="flex-start" spacing={2}>
                <HStack alignItems="center" spacing={2}>
                  <Text fontSize="xl" fontWeight="bold">
                    {user?.nickname}
                  </Text>
                  {/* <ReactCountryFlag countryCode="HR" svg /> */}
                </HStack>

                <Text fontSize="md">Total games played: {basicStats?.totalGames || 0}</Text>
                <Text mt={-2} fontSize="md">
                  Total modes played: {basicStats?.totalModes || 0}
                </Text>

                <Text mt={-2} fontSize="md">
                  {user && <>Member since: {format(user.createdAt, 'dd MMM yyyy')}</>}
                </Text>
              </VStack>
            </Flex>

            <Grid
              templateColumns={{
                base: `repeat(1, 1fr)`,
                sm: `repeat(2, 1fr)`,
                lg: `repeat(4, 1fr)`,
              }}
              gap={6}
              mt={8}
            >
              {stats?.map((stat) => (
                <Box
                  mt={{ base: 8 }}
                  px={{ base: 0, sm: 8 }}
                  py={{ base: 0, sm: 8 }}
                  bg={{ base: 'transparent', sm: 'white' }}
                  boxShadow={{ base: 'none', sm: 'md' }}
                  borderRadius={{ base: 'none', sm: 'xl' }}
                  alignSelf={'center'}
                >
                  <Text fontSize="xl" fontWeight="bold">
                    {capitalize(stat.mode)} Mode Stats
                  </Text>
                  <Flex justifyContent={'space-between'} gap={12} pt={{ base: 4 }}>
                    <Stat>
                      <Flex justifyContent={'space-between'}>
                        <Box pr={{ base: 2, md: 4 }}>
                          <StatLabel fontWeight={'medium'} isTruncated>
                            MapIQ
                          </StatLabel>
                          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                            {stat?.mapIq.toFixed(0) || '-'}
                          </StatNumber>
                        </Box>
                        <Box my={'auto'} color={'gray.800'} alignContent={'center'}>
                          <MdLocationPin size={32} />
                        </Box>
                      </Flex>
                    </Stat>
                    <Stat>
                      <Flex justifyContent={'space-between'}>
                        <Box pr={{ base: 2, md: 4 }}>
                          <StatLabel fontWeight={'medium'} isTruncated>
                            High Score
                          </StatLabel>
                          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                            {stat?.highScore || '-'}
                          </StatNumber>
                        </Box>
                        <Box my={'auto'} color={'gray.800'} alignContent={'center'}>
                          <FaTrophy size={32} />
                        </Box>
                      </Flex>
                    </Stat>
                  </Flex>
                </Box>
              ))}
            </Grid>
          </VStack>
        )}

        <Box
          mx={{ base: 0, sm: 8, lg: 16 }}
          mb={{ base: 8 }}
          px={{ base: 8 }}
          py={{ base: 8 }}
          bg={{ base: 'transparent', sm: 'white' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Flex justifyContent="space-between">
            <Text fontSize="xl" fontWeight="bold">
              Game History
            </Text>

            <Select
              w={64}
              placeholder="Select game mode"
              onChange={(e) => setHistoryMode(e.currentTarget.value as GameMode)}
              defaultValue={historyMode}
            >
              {options?.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Flex>

          <DataTable columns={columns} data={games || []} title="" />
        </Box>
      </Box>
    </Layout>
  );
};
