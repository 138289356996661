import { Box } from '@chakra-ui/react';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { globalContext } from 'context';
import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';

export const LogoutPage = () => {
  const { dispatch } = useContext(globalContext);

  useEffect(() => {
    Cookies.remove('token');
    dispatch({ type: 'logout' });
    document.location.href = '/login';
  }, []);

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue"></Box>
    </Layout>
  );
};
