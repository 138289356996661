import { Button, Stack } from '@chakra-ui/react';
import { GoogleIcon } from 'components/icons/GoogleIcon';
import { FaFacebook } from 'react-icons/fa';
import { API_HOST } from 'utils/api';

const providers = [
  { name: 'Google', icon: <GoogleIcon />, enabled: true, urlPath: '/auth/google' },
  { name: 'Facebook', icon: <FaFacebook />, enabled: true, urlPath: '/auth/facebook' },
] as const;

interface Props {
  btnPrefix?: string;
}

export const OAuthButtonGroup: React.FC<Props> = ({ btnPrefix = 'Sign in with' }) => (
  <>
    <Stack spacing="4">
      {providers.map(({ name, icon, enabled, urlPath }) => (
        <Button
          key={name}
          variant={name === 'Google' ? 'outline' : 'solid'}
          colorScheme={name === 'Google' ? 'gray' : name.toLowerCase()}
          leftIcon={icon}
          isDisabled={!enabled}
          onClick={() => (window.location.href = `${API_HOST}${urlPath}`)}
        >
          {btnPrefix} {name}
        </Button>
      ))}
    </Stack>
  </>
);
