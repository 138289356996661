import {
  Box,
  Flex,
  Heading,
  Icon,
  Select,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { IoMdPin } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { useEffect, useState } from 'react';
import { GameMode } from 'types/api/game.dto';
import { LeaderboardRowDto } from 'types/api/leaderboard.dto';
import { GameModeOptionCopyDto } from 'types/api/utils.dto';
import { useDataResource } from 'utils/api';

export const LeaderboardPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [gameMode, setGameMode] = useState<GameMode>(
    (searchParams.get('mode') as GameMode) ?? GameMode.CLASSIC
  );

  const { data, isLoading } = useDataResource<LeaderboardRowDto[]>('/games/leaderboard', false, {
    mode: gameMode,
  });

  const { data: options } = useDataResource<GameModeOptionCopyDto[]>(
    '/utils/game-modes-copy',
    false
  );

  useEffect(() => {
    document.title = 'MapIQ Leaderboard - Top Players Around the Globe';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={false}
          buttons={[
            {
              label: 'Hub',
              onClick: () => navigate('/hub'),
              bgColor: 'green.300',
              icon: <Icon h={5} w={5} as={IoMdPin} />,
            },
          ]}
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <VStack py={4} px={16}>
          <Heading as="h1" size="xl" fontWeight={900} mb={16}>
            Leaderboards
          </Heading>

          <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={{ base: 'transparent', sm: 'white' }}
            boxShadow={{ base: 'none', sm: 'md' }}
            borderRadius={{ base: 'none', sm: 'xl' }}
            w="full"
          >
            <Flex justifyContent="space-between" pb={8}>
              <Text fontSize="xl" fontWeight="bold">
                Top 100 Players
              </Text>

              <Select
                w={64}
                placeholder="Select game mode"
                onChange={(e) => setGameMode(e.currentTarget.value as GameMode)}
                defaultValue={gameMode}
              >
                {options?.map((option) => (
                  <option key={option.key} value={option.key}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Flex>
            {isLoading ? (
              <Stack>
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
              </Stack>
            ) : (
              <TableContainer>
                <Table variant="simple" size="lg" colorScheme="blue">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Nickname</Th>
                      <Th>Date</Th>
                      <Th isNumeric>Duration</Th>
                      <Th isNumeric>Score</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.map((row, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{row.nickname}</Td>
                        <Td>{format(new Date(row.date), 'dd MMM yyyy')}</Td>
                        <Td isNumeric>{row.duration}</Td>
                        <Td isNumeric>{row.score}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </VStack>
      </Box>
    </Layout>
  );
};
