import { useBoolean } from '@chakra-ui/hooks';
import { useGameInfo } from 'hooks/game-info.hook';
import { useEffect, useState } from 'react';
import { GameState, GameStateType } from 'types/GameState';
import { ScreenPoint } from 'types/Point';
import { FinishGameDto, GameDto } from 'types/api/game.dto';
import { UserDto } from 'types/api/user.dto';
import { useInterval, useTimeout } from 'usehooks-ts';
import { getScore } from 'utils/score.util';
import { getGeoCoordinate, getHaversineDistance } from 'utils/screen-geometry';
import { GameView } from './GameView';

interface Props {
  gameInitial?: GameDto;
  user?: UserDto;
  onGameEnded: (id: string, dto: FinishGameDto) => void;
}

export const GameController: React.FC<Props> = ({ user, gameInitial, onGameEnded }) => {
  const [secondsPassed, setSecondsPassed] = useState<number>(0);
  const [levelTime, setLevelTime] = useState<number>(1);

  const [gameState, setGameState] = useState<GameStateType>(GameState.Loading);

  const [showCorrectLocation, setShowCorrectLocation] = useBoolean(false);
  const [correctLocation, setCorrectLocation] = useState<ScreenPoint>();

  const [currentLevel, setCurrentLevel] = useState<number>(0);
  const [currentLocation, setCurrentLocation] = useState<number>(0);

  const [locationScore, setLocationScore] = useState<number>(0);
  const [levelScore, setLevelScore] = useState<number>(0);
  const [totalScore, setTotalScore] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);

  const [game, setGame] = useState<GameDto | undefined>(gameInitial);
  const [mapIq, setMapIq] = useState<number>(0);

  const {
    pointsToPass,
    levelLocations,
    scorePerCity,
    cityName,
    countryCode,
    isLevelOver,
    isGameOver,
    nextLevelLocations,
    nextLevelPointsToPass,
    correctLocationPoint,
    currentCityGeoLocation,
    getTotalCities,
    totalLevels,
  } = useGameInfo(currentLevel, currentLocation, levelScore, game);

  useInterval(
    () => {
      setSecondsPassed((prevState) => prevState + 0.1);
    },
    gameState === GameState.Started ? 100 : null
  );

  useTimeout(
    () => {
      setCorrectLocation(correctLocationPoint);
      setShowCorrectLocation.off();
    },
    showCorrectLocation ? 800 : null
  );

  useTimeout(
    () => {
      setGameState(GameState.Paused);
      setCorrectLocation(undefined);
    },
    gameState === GameState.ShowCorrectLocation ? 3000 : null
  );

  useEffect(() => {
    switch (gameState) {
      case GameState.Started:
        setLocationScore(0);
        break;
      case GameState.Ended:
        break;
      case GameState.Paused:
        break;
      case GameState.ShowCorrectLocation:
        setShowCorrectLocation.on();
        break;
      case GameState.LeveledUp:
        setCurrentLocation(0);
        break;
      default:
        break;
    }
  }, [gameState, setShowCorrectLocation, currentLevel, levelScore]);

  useEffect(() => {
    if (gameState !== GameState.Loading && secondsPassed - levelTime >= -0.1) {
      setGameState(GameState.ShowCorrectLocation);
      setLocationScore(0);
    }
  }, [secondsPassed]);

  useEffect(() => {
    if (gameInitial) {
      setGame(gameInitial);
      setGameState(GameState.Loaded);
      setLevelTime(gameInitial.config.levelTime);

      if (gameInitial.mapIq) {
        setMapIq(gameInitial.mapIq);
      }
    }
  }, [gameInitial]);

  useEffect(() => {
    if (isLevelOver) {
      setGameState(GameState.LeveledUp);
    }
  }, [isLevelOver]);

  useEffect(() => {
    if (isGameOver) {
      setGameState(GameState.Ended);
      endGame();
    }
  }, [isGameOver, onGameEnded, totalScore, gameInitial]);

  const onMapClick = (point: ScreenPoint) => {
    const mapPoint = { x: point.x, y: point.y - 80 };
    const clickedCityGeoLocation = getGeoCoordinate(mapPoint);

    const distance = getHaversineDistance(currentCityGeoLocation, clickedCityGeoLocation);
    setDistance(distance);

    const locationScore = getScore(scorePerCity, distance, secondsPassed, levelTime);
    setLocationScore(locationScore);
    setLevelScore((prev) => prev + locationScore);
    setTotalScore((prev) => prev + locationScore);
  };

  const endGame = () => {
    if (gameInitial) {
      onGameEnded(gameInitial.id, {
        score: totalScore,
        finished: new Date().toISOString(),
      });
    }
  };

  const startNextLevel = () => {
    resetLocation();
    setLevelScore(0);
    setCurrentLevel((prev) => prev + 1);
  };

  const startNextCity = () => {
    if (currentLocation < levelLocations - 1) {
      resetLocation();
      setLocationScore(0);
    }
    setCurrentLocation((prev) => prev + 1);
  };

  const resetLocation = () => {
    setGameState(GameState.Started);
    setSecondsPassed(0);
    setDistance(0);
  };

  return (
    <GameView
      secondsPassed={secondsPassed}
      levelTime={levelTime}
      gameState={gameState}
      changeGameState={setGameState}
      correctLocation={correctLocation}
      currentLevel={currentLevel}
      currentLocation={currentLocation}
      levelScore={levelScore}
      totalScore={totalScore}
      locationScore={locationScore}
      totalLevelLocations={levelLocations}
      totalLevels={totalLevels}
      onMapClickCallback={onMapClick}
      startNextLevel={startNextLevel}
      startNextCity={startNextCity}
      pointsToPass={pointsToPass}
      cityName={cityName}
      countryCode={countryCode}
      scorePerCity={scorePerCity}
      nextLevelLocations={nextLevelLocations}
      nextLevelPointsToPass={nextLevelPointsToPass}
      distance={distance}
      nickname={user ? user.nickname : game?.nickname || ''}
      getTotalCities={getTotalCities}
      guest={!user}
      mapIq={mapIq}
      endGame={endGame}
    />
  );
};
