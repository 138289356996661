export enum GameState {
  Loading = "loading",
  Loaded = "loaded",
  Started = "started",
  Ended = "ended",
  Paused = "paused",
  LeveledUp = "leveled_up",
  ShowCorrectLocation = "show_correct_location",
}

export type GameStateValue = keyof typeof GameState;
export type GameStateType = (typeof GameState)[GameStateValue];
