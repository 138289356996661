import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Image,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { HiUserAdd } from 'react-icons/hi';
import { IoMdPin } from 'react-icons/io';
import { LuLogIn } from 'react-icons/lu';
import { MdLeaderboard } from 'react-icons/md';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';

import { useEffect } from 'react';
import globeImg from './globe-bg.png';

export const LandingPage = () => {
  const navigate = useNavigate();
  const screenSize = useBreakpointValue(['xs', 'sm', 'md', 'lg']);

  useEffect(() => {
    document.title = 'MapIQ - Test Your Geography Skills Online';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={false}
          buttons={[
            {
              label: 'Sign Up',
              onClick: () => navigate('/register'),
              bgColor: 'green.300',
              icon: <Icon h={5} w={5} as={HiUserAdd} />,
            },
            {
              label: 'Login',
              onClick: () => navigate('/login'),
              bgColor: 'gray.300',
              icon: <Icon h={5} w={5} as={LuLogIn} />,
            },
          ]}
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <SimpleGrid columns={[1, 1, 1, 2]} h="100%" w="100%" maxW="100%">
          <VStack
            p={[8, 8, 16, 32]}
            alignItems="flex-start"
            justifyContent="center"
            width="full"
            height="100%"
            spacing={8}
          >
            <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl', lg: '2xl' }} fontWeight={500}>
              Can you pinpoint locations around the world on a blind map? With City Challenges like
              Classic, Short, and Rapid, along with specialized modes like Stadiums and Heritage
              Sites, MapIQ tests your knowledge at every level. Track your progress, compete on the
              leaderboard, and see how high you can score. Let's find out how sharp your map skills
              really are!
            </Text>

            <ButtonGroup spacing={4} alignSelf={{ base: 'center', lg: 'flex-start' }}>
              <Button
                leftIcon={<Icon h={5} w={5} as={IoMdPin} />}
                bgColor="gray.300"
                size={screenSize}
                onClick={() => navigate('/hub')}
              >
                Play now
              </Button>
              <Button
                bgColor="green.300"
                size={screenSize}
                leftIcon={<Icon h={5} w={5} as={MdLeaderboard} />}
                onClick={() => navigate('/leaderboard')}
              >
                Leaderboard
              </Button>
            </ButtonGroup>
          </VStack>
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Image src={globeImg} alt="bg-map" objectFit="cover" />
          </Box>
        </SimpleGrid>
      </Box>
    </Layout>
  );
};
