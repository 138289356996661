import { Box, Progress, Text } from '@chakra-ui/react';

interface Props {
  level: number;
  levels?: number;
}

const progressValues10: Record<number, number> = {
  1: 1,
  2: 12,
  3: 23,
  4: 34,
  5: 44,
  6: 55,
  7: 66,
  8: 77,
  9: 88,
  10: 100,
};

const progressValues5: Record<number, number> = {
  1: 1,
  2: 25,
  3: 50,
  4: 75,
  5: 100,
};

export const LevelProgress: React.FC<Props> = ({ level, levels = 10 }) => {
  const progressValues = levels === 5 ? progressValues5 : progressValues10;
  return (
    <>
      <Text fontSize="md" fontWeight={600}>
        Level Progress
      </Text>
      <Progress value={progressValues[level]} colorScheme="blue" size="lg" mt={2} />
      <Box display="flex" justifyContent="space-between" mt={2}>
        {Array.from({ length: levels }).map((_, i) => (
          <Text key={i} fontSize="sm" fontWeight={400}>
            {i + 1}
          </Text>
        ))}
      </Box>
    </>
  );
};
