import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { OAuthButtonGroup } from 'components/ui/OAuthButtons';
import { PasswordField } from 'components/ui/PasswordField';
import { useEffect, useState } from 'react';
import { LuLogIn } from 'react-icons/lu';
import { UserCreateDto, UserDto } from 'types/api/user.dto';
import { sendRequest } from 'utils/api';

export const RegisterPage = () => {
  const navigate = useNavigate();

  const toast = useToast();

  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [createAccountLoading, setCreateAccountLoading] = useState(false);

  useEffect(() => {
    document.title = 'MapIQ - Create an Account';
  }, []);

  const cleanErrors = () => {
    setEmailError('');
    setNicknameError('');
    setPasswordError('');
    setRepeatPasswordError('');
  };

  const validateForm = (): boolean => {
    if (email.length === 0) {
      setEmailError('Email is required');
      return false;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Email is invalid');
      return false;
    }

    if (nickname.length < 6 || nickname.length > 15) {
      setNicknameError('Nickname needs to be between 6 and 15 characters long');
      return false;
    }

    if (/\s/g.test(nickname)) {
      setNicknameError('Nickname cannot contain spaces');
      return false;
    }

    if (!/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(password)) {
      setPasswordError(
        'Password needs to be at least 8 characters long and contain 1 letter and 1 number'
      );
      return false;
    }

    if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords do not match');
      return false;
    }

    return true;
  };

  const createAccount = async () => {
    cleanErrors();
    setCreateAccountLoading(true);

    if (!validateForm()) {
      setCreateAccountLoading(false);

      return;
    }

    try {
      await sendRequest<UserCreateDto, UserDto>('/users', 'POST', {
        email,
        nickname,
        password,
      });
      navigate('/login');
    } catch (e) {
      toast({
        title: 'Account creation failed',
        description: (e as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }

    setCreateAccountLoading(false);
  };

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={false}
          buttons={[
            {
              label: 'Login',
              onClick: () => navigate('/login'),
              bgColor: 'green.300',
              icon: <Icon h={5} w={5} as={LuLogIn} />,
            },
          ]}
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: '12' }} px={{ base: '0', sm: '8' }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size={{ base: 'xs', md: 'sm', lg: 'lg' }}>Create your account</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'white' }}
              boxShadow={{ base: 'none', sm: 'md' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl isInvalid={emailError.length > 0}>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      isRequired
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    {emailError.length > 0 && <FormErrorMessage>{emailError}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={nicknameError.length > 0}>
                    <Input
                      id="nickname"
                      type="text"
                      placeholder="Nickname"
                      isRequired
                      onChange={(e) => setNickname(e.currentTarget.value)}
                    />
                    {nicknameError.length > 0 && (
                      <FormErrorMessage>{nicknameError}</FormErrorMessage>
                    )}
                  </FormControl>
                  <PasswordField
                    id="password"
                    placeholder="Password"
                    isRequired
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    error={passwordError}
                  />
                  <PasswordField
                    id="repeatPassword"
                    placeholder="Repeat password"
                    isRequired
                    onChange={(e) => setRepeatPassword(e.currentTarget.value)}
                    error={repeatPasswordError}
                  />
                </Stack>
                <Stack spacing="6">
                  <Button
                    bg="blue.500"
                    color="white"
                    isLoading={createAccountLoading}
                    onClick={createAccount}
                  >
                    Create an account
                  </Button>
                  <OAuthButtonGroup btnPrefix="Continue with" />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};
