import React, { useEffect, useState } from 'react';

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import { LevelProgress } from 'components/common/LevelProgress';
import { VscDebugStart } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/enums/routes.enum';
import { BaseModalProps } from './interfaces';

interface StartGameModalProps extends BaseModalProps {
  startGame: () => void;
  loading: boolean;
  cities: number;
  scoreToPass: number;
  totalScore: number;
  nickname: string;
  levels: number;
  guest: boolean;
}

export const StartGameModal: React.FC<StartGameModalProps> = ({
  open,
  startGame,
  loading,
  cities,
  levels,
  scoreToPass,
  totalScore,
  nickname,
  guest,
}) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      motionPreset="scale"
      autoFocus={false}
      onClose={() => {
        setIsOpen(false);
        navigate(Routes.HUB);
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent bgColor="bgLightBlue" p={8}>
        <ModalHeader>
          {loading ? (
            'Creating a game...'
          ) : (
            <>
              Welcome <i>{nickname}</i>
            </>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8}>
          {loading ? (
            <Box w="full" display="flex" justifyContent="center" pt={8} pb={8}>
              <Spinner size="xl" speed="1s" label="Loading..." />
            </Box>
          ) : (
            <>
              <LevelProgress level={1} levels={levels} />

              <Box display="flex" justifyContent="space-between" alignItems="center" pt={4}>
                <Box
                  borderWidth="1px"
                  borderColor="gray.300"
                  p={4}
                  mt={4}
                  mb={4}
                  borderRadius="md"
                  flex={3}
                >
                  <StatGroup>
                    <Stat>
                      <StatLabel>Total Points</StatLabel>
                      <StatNumber>{0}</StatNumber>
                    </Stat>

                    <Stat>
                      <StatLabel>No. Locations in level {1}</StatLabel>
                      <StatNumber>{cities}</StatNumber>
                    </Stat>
                  </StatGroup>
                </Box>

                <Box display="flex" justifyContent="flex-end" alignItems="center" flex={2}>
                  <CircularProgress
                    value={(scoreToPass / totalScore) * 100}
                    color="blue.400"
                    thickness="10px"
                    size="180px"
                  >
                    <CircularProgressLabel>
                      <Text fontSize="md" fontWeight={600}>
                        {scoreToPass}/{totalScore}
                      </Text>
                      <Text fontSize="sm" fontWeight={500}>
                        TO PASS
                      </Text>
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
              </Box>

              <Box pt={4} w="full" display="flex" justifyContent="center">
                <Text fontSize="lg" as="b">
                  Good Luck - Ready, Set, Pin!
                </Text>
              </Box>
              {guest && (
                <Alert status="warning" mt={8}>
                  <AlertIcon />
                  Seems you are playing as a guest. Your progress will not be saved and you won't be
                  able to see your MapIQ without an account.
                </Alert>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Box display="flex" justifyContent="center" w="full">
            {loading ? null : (
              <Button
                bgColor="green.300"
                size="md"
                leftIcon={<Icon as={VscDebugStart} w={6} h={6} />}
                onClick={startGame}
                isDisabled={loading}
              >
                Start Game
              </Button>
            )}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
