import { Box, Container, Heading, Icon, Text } from '@chakra-ui/react';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { globalContext } from 'context';
import { useContext, useEffect } from 'react';
import { HiUserAdd } from 'react-icons/hi';
import { LuLogIn } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export const TermsOfServicePage = () => {
  const navigate = useNavigate();

  const { state } = useContext(globalContext);

  useEffect(() => {
    document.title = 'MapIQ Terms of Service';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={state.isAuthenticated}
          buttons={
            state.isAuthenticated
              ? []
              : [
                  {
                    label: 'Sign Up',
                    onClick: () => navigate('/register'),
                    bgColor: 'green.300',
                    icon: <Icon h={5} w={5} as={HiUserAdd} />,
                  },
                  {
                    label: 'Login',
                    onClick: () => navigate('/login'),
                    bgColor: 'gray.300',
                    icon: <Icon h={5} w={5} as={LuLogIn} />,
                  },
                ]
          }
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: 4, sm: 8 }}>
          <Heading as="h1" size="xl" fontWeight={900} textAlign="center">
            Terms of service "Terms"
          </Heading>
        </Container>
        <Box
          mx={{ base: 0, sm: 8, lg: 16 }}
          mb={{ base: 8 }}
          px={{ base: 8 }}
          py={{ base: 8 }}
          bg={{ base: 'transparent', sm: 'white' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Heading as="h2" size="md" mb={4}>
            1. Acceptance of Terms
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            By accessing and using MapIQ, you accept and agree to be bound by these terms. If you do
            not agree, please do not use our service.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            2. Changes to Terms
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            We reserve the right to modify these terms at any time. Any changes will be posted on
            this page, and your continued use of MapIQ signifies your acceptance of the updated
            terms.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            3. User Accounts
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            You must create an account to use certain features of MapIQ, such as tracking your stats
            and accessing the leaderboard. You agree to provide accurate information and to update
            it as necessary. You are responsible for maintaining the confidentiality of your account
            and password.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            4. Use of Service
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            You agree to use MapIQ for lawful purposes only. You must not use the service to engage
            in any activity that is illegal, harmful, or otherwise inappropriate.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            5. Guest Mode
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            You may play MapIQ in guest mode without creating an account. However, please note that
            your game stats will not be tracked, and you will not be able to access certain features
            like the leaderboard and personal dashboard.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            6. Intellectual Property
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            All content, features, and functionality on MapIQ are owned by us and are protected by
            intellectual property laws. You may not reproduce, distribute, or create derivative
            works from our content without our permission.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            7. Limitation of Liability
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            MapIQ is provided "as is" and we make no warranties regarding its functionality or
            availability. We are not liable for any damages resulting from the use or inability to
            use the service.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            8. Governing Law
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            These terms are governed by and construed in accordance with the laws of our
            jurisdiction, without regard to its conflict of law principles.
          </Text>
        </Box>
      </Box>
    </Layout>
  );
};
