import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Container } from '@chakra-ui/react';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export const AuthCallbacksPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams('access_token');

  useEffect(() => {
    if (searchParams.has('access_token')) {
      // Get the code and state from the searchParams
      const token = searchParams.get('access_token');
      if (token) {
        Cookies.set('token', token);
      }

      searchParams.delete('access_token');
      setSearchParams(searchParams);

      // Redirect to the login page
      document.location.href = '/auth-redirect';
    }
  }, [searchParams, setSearchParams, navigate]);

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: '12' }} px={{ base: '0', sm: '8' }}></Container>
      </Box>
    </Layout>
  );
};
