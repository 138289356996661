import React, { useEffect, useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { LevelProgress } from 'components/common/LevelProgress';
import { useNavigate } from 'react-router-dom';
import { BaseModalProps } from './interfaces';

interface NextLevelModalProps extends BaseModalProps {
  points: number;
  level: number;
  pointsToAdvance: number;
  maxLevelPoints: number;
  maxNextLevelPoints: number;
  totalPoints: number;
  totalLevels: number;
  nextLevelCities: number;
  nickname: string;
  nextLevel(): void;
  finishGame(): void;
}

export const NextLevelModal: React.FC<NextLevelModalProps> = ({
  open,
  level,
  points,
  pointsToAdvance,
  maxLevelPoints,
  maxNextLevelPoints,
  nextLevel,
  totalPoints,
  totalLevels,
  nickname,
  nextLevelCities,
  finishGame,
}) => {
  const { isOpen: showEndDialog, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const ldRef = useRef(null);
  const cancelRef = useRef(null);

  const [levelIndex] = useState(level);

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    const handleSpacePress = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        nextLevel();
      }
    };

    window.addEventListener('keydown', handleSpacePress);

    return () => {
      window.removeEventListener('keydown', handleSpacePress);
    };
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        isCentered
        autoFocus={false}
        onClose={() => {
          setIsOpen(false);
          navigate('/profile');
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="xl"
        leastDestructiveRef={ldRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent bgColor="bgLightBlue" p={8}>
          <AlertDialogHeader>
            {['Bravo!', 'Excellent.', 'Good job!'][Math.floor(Math.random() * 3)]} {nickname}{' '}
            reached level {levelIndex + 1}!
          </AlertDialogHeader>
          <AlertDialogBody pb={8} pt={4}>
            <Box display="flex" alignItems="center" justifyContent="center" pb={8}>
              <CircularProgress
                value={(points / maxLevelPoints) * 100}
                color="green.400"
                thickness="10px"
                size="180px"
              >
                <CircularProgressLabel>
                  <Text fontSize="lg" fontWeight={600}>
                    {points.toFixed(1)}
                  </Text>
                  <Text fontSize="sm" fontWeight={500}>
                    LEVEL {levelIndex} POINTS
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Box>

            <LevelProgress level={levelIndex + 1} levels={totalLevels} />

            <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
              <Box
                borderWidth="1px"
                borderColor="gray.300"
                p={4}
                mt={4}
                mb={4}
                borderRadius="md"
                flex={3}
              >
                <StatGroup>
                  <Stat>
                    <StatLabel>Total Points</StatLabel>
                    <StatNumber>{totalPoints.toFixed(1)}</StatNumber>
                  </Stat>

                  <Stat>
                    <StatLabel>No. Locations in level {levelIndex + 1}</StatLabel>
                    <StatNumber>{nextLevelCities}</StatNumber>
                  </Stat>
                </StatGroup>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center" flex={2}>
                <CircularProgress
                  value={(pointsToAdvance / maxNextLevelPoints) * 100}
                  color="blue.400"
                  thickness="10px"
                  size="180px"
                >
                  <CircularProgressLabel>
                    <Text fontSize="lg" fontWeight={600}>
                      {pointsToAdvance}/{maxNextLevelPoints}
                    </Text>
                    <Text fontSize="sm" fontWeight={500}>
                      TO PASS
                    </Text>
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
            </Box>
          </AlertDialogBody>

          <VStack spacing={4}>
            <Box display="flex" justifyContent="center" w="full">
              <ButtonGroup spacing={4}>
                <Button bgColor="green.300" size="md" onClick={nextLevel}>
                  Start Level {levelIndex + 1}
                </Button>

                <Button bgColor="red.300" size="md" onClick={onOpen}>
                  Finish Game
                </Button>
              </ButtonGroup>
            </Box>
            <Text fontSize="md" textAlign="center">
              You can press{' '}
              <i>
                <b>space</b>
              </i>{' '}
              key to start next level.
            </Text>
          </VStack>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={showEndDialog} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Finish Game
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to end the game?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  finishGame();
                  navigate('/hub');
                }}
                ml={3}
              >
                Finish Game
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
