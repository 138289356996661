import { extendTheme } from "@chakra-ui/react";

import "@fontsource/figtree/300.css";
import "@fontsource/figtree/400.css";
import "@fontsource/figtree/500.css";
import "@fontsource/figtree/600.css";
import "@fontsource/figtree/700.css";
import "@fontsource/figtree/800.css";
import "@fontsource/figtree/900.css";

export const theme = extendTheme({
  fonts: {
    body: `"Figtree", sans-serif;`,
    heading: `"Figtree", sans-serif;`,
    mono: `"Figtree", sans-serif;`,
  },
  colors: {
    bgLightBlue: "#EBFAFF",
  },
});
