import { globalContext } from 'context';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { UserDto } from 'types/api/user.dto';
import { useDataResource } from 'utils/api';

export const Shell: React.FC<PropsWithChildren> = ({ children }) => {
  const { dispatch } = useContext(globalContext);

  const { data: user, isLoading: userLoading } = useDataResource<UserDto>('/users/me', true);

  useEffect(() => {
    if (user) {
      dispatch({ type: 'set-user', payload: user });
    }

    if (!user && !userLoading) {
      dispatch({ type: 'logout' });
    }
  }, [user, dispatch, userLoading]);

  return <>{userLoading ? null : children}</>;
};
