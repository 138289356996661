import {
  Box,
  chakra,
  Link as ChakraLink,
  Container,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={'white'}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target="_blank"
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: 'gray.200',
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer = () => {
  return (
    <Box bg={'gray.900'}>
      <Container
        maxW="full"
        as={Stack}
        px={[4, 8]}
        py={4}
        mx={0}
        direction={{ base: 'column', md: 'row' }}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text color="white">© {new Date().getFullYear()} MapIQ. All rights reserved.</Text>
        <Box display="flex" alignItems={'center'}>
          <Box display="flex" color="white">
            <Text fontSize={'md'} px={5}>
              <ChakraLink as={ReactRouterLink} to="/about">
                About
              </ChakraLink>
            </Text>

            <Text fontSize={'md'} px={5}>
              <ChakraLink as={ReactRouterLink} to="/privacy-policy">
                Privacy
              </ChakraLink>
            </Text>

            <Text fontSize={'md'} px={5}>
              <ChakraLink as={ReactRouterLink} to="/terms-of-service">
                Terms
              </ChakraLink>
            </Text>
          </Box>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href="https://x.com/mapiq_official">
              <FaXTwitter />
            </SocialButton>
            <SocialButton label={'Discord'} href="https://discord.gg/ucNZs8F3BY">
              <FaDiscord />
            </SocialButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
