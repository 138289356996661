export const getScore = (
  maxScore: number,
  distanceKm: number,
  time: number,
  totalTime: number
): number => {
  if (distanceKm > 3000) {
    return (400 * 3000) / distanceKm;
  }

  const DISTANCE_WEIGHT = 0.9;
  const TIME_WEIGHT = 0.1;

  // Assuming the maximum reasonable distance for full score is 10km
  const MAX_REASONABLE_DISTANCE_KM = 3000;

  // Linearly decrease score based on distance. Full score for 0km, no score for MAX_REASONABLE_DISTANCE_KM or more.
  const distanceScore = Math.max(1 - distanceKm / MAX_REASONABLE_DISTANCE_KM, 0);

  // Linearly decrease score based on time. Full score for 0 time, no score for totalTime or more.
  const timeScore = Math.max(1 - time / totalTime, 0);

  // Combine scores with weights
  let score = maxScore * (DISTANCE_WEIGHT * distanceScore + TIME_WEIGHT * timeScore);

  if (score < 0) score = 0;

  return parseInt(score.toFixed(0));
};
