import { CountryCode } from 'utils/iso2.mapper';

export interface GameDto {
  id: string;
  config: GameConfigDto;
  score: number;
  started: string;
  finished?: string;
  nickname?: string;
  mapIq?: number;
}

export interface GameConfigDto {
  noLevels: number;
  noCities: number;
  levelTime: number;
  levels: LevelDto[];
}

export interface CityDto {
  city: string;
  lat: number;
  long: number;
  iso2: CountryCode;
  capital: string;
  population: string;
}

export interface LevelDto {
  scorePerCity: number;
  scoreToAdvance: number;
  cities: CityDto[];
  attempts: AttemptDto[];
}

export interface AttemptDto {
  distance: number;
  long: number;
  lat: number;
  score: number;
}

export enum GameMode {
  CLASSIC = 'classic',
  SHORT = 'short',
  RAPID = 'rapid',
  STADIUMS = 'stadiums',
  UNESCO = 'unesco',
}

export interface CreateGameDto {
  started: string;
  mode: GameMode;
  userId?: string;
}

export interface FinishGameDto {
  finished: string;
  score: number;
}

export interface UserGamesDto {
  games: GameDto[];
}
