import React, { useEffect, useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  CircularProgressLabel,
  Icon,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import { LevelProgress } from 'components/common/LevelProgress';
import { MdLeaderboard } from 'react-icons/md';
import { useMatch, useNavigate } from 'react-router-dom';
import { BaseModalProps } from './interfaces';

interface GameEndedModalProps extends BaseModalProps {
  points: number;
  level: number;
  mapIq: number;
  cities: number;
  nickname: string;
  maxPoints: number;
  totalLevels: number;
}

export const GameEndedModal: React.FC<GameEndedModalProps> = ({
  open,
  points,
  level,
  nickname,
  maxPoints,
  cities,
  mapIq,
  totalLevels,
}) => {
  const navigate = useNavigate();
  const match = useMatch('/play/:mode');
  const mode = match?.params.mode;

  const ldRef = useRef(null);

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered
      autoFocus={false}
      onClose={() => {
        setIsOpen(false);
        navigate('/hub');
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
      leastDestructiveRef={ldRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent bgColor="bgLightBlue" p={8}>
        <AlertDialogHeader>Game ended</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody pb={8} pt={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" pb={4}>
            <Text pr={32} fontWeight={400}>
              {level === 10 ? 'Congratulations' : ''} {nickname} reached level <b>{level}</b> and
              got <b>{points.toFixed(1)}</b> total points
            </Text>

            <CircularProgress
              value={(points / maxPoints) * 100}
              color="green.400"
              thickness="10px"
              size="180px"
            >
              <CircularProgressLabel>
                <Text fontSize="md" fontWeight={600}>
                  {points.toFixed(1)}
                </Text>

                <Text fontSize="sm" fontWeight={500}>
                  TOTAL POINTS
                </Text>
              </CircularProgressLabel>
            </CircularProgress>
          </Box>

          <LevelProgress level={level} levels={totalLevels} />

          <Box
            borderWidth="1px"
            borderColor="gray.300"
            p={4}
            mt={4}
            mb={4}
            borderRadius="md"
            flex={3}
          >
            <StatGroup>
              <Stat>
                <StatLabel>Locations Total</StatLabel>
                <StatNumber>{cities}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>MapIQ</StatLabel>
                <StatNumber>{mapIq}</StatNumber>
              </Stat>
            </StatGroup>
          </Box>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Box display="flex" justifyContent="center" w="full">
            <ButtonGroup spacing={4}>
              <Button bgColor="gray.300" size="md" onClick={() => navigate(0)}>
                Play again
              </Button>
              <Button
                bgColor="green.300"
                size="md"
                leftIcon={<Icon as={MdLeaderboard} w={6} h={6} />}
                onClick={() => navigate(`/leaderboard?mode=${mode}`)}
              >
                Leaderboard
              </Button>
            </ButtonGroup>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
