import { Layout } from 'components/layout/Layout';
import { GameController } from 'modules/Game/GameController';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CreateGameDto, FinishGameDto, GameDto, GameMode } from 'types/api/game.dto';
import { UserDto } from 'types/api/user.dto';
import { sendRequest, useDataResource } from 'utils/api';

export const GamePage = () => {
  const hasRunOnce = useRef(false);
  const { mode } = useParams<{ mode: GameMode }>() as { mode: GameMode };

  const [game, setGame] = useState<GameDto>();

  const { data: user, isLoading: userLoading } = useDataResource<UserDto>('/users/me', true);

  useEffect(() => {
    document.title = `MapIQ - Play ${mode} challenge`;
  }, []);

  const createGame = async () => {
    const newGame = await sendRequest<CreateGameDto, GameDto>(
      '/games/start',
      'POST',
      { started: new Date().toISOString(), mode },
      true
    );

    if (newGame) {
      setGame(newGame);
    }
  };

  const finishGame = async (id: string, dto: FinishGameDto): Promise<void> => {
    const game = await sendRequest<FinishGameDto, GameDto>(
      `/games/finish/${id}`,
      'PATCH',
      dto,
      false
    );
    setGame(game);
  };

  useEffect(() => {
    if (hasRunOnce.current && game) return;

    createGame();

    hasRunOnce.current = true;
  }, []);

  return (
    <Layout showFooter={false} showNavbar={false}>
      {userLoading ? null : (
        <GameController
          user={user}
          gameInitial={game}
          onGameEnded={(id, dto) => finishGame(id, dto)}
        />
      )}
    </Layout>
  );
};
