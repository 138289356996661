import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { useState } from 'react';
import { sendRequest } from 'utils/api';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const toast = useToast();

  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);

  const sendRecoveryEmail = async () => {
    if (!email) return;

    setSending(true);

    try {
      const r = await sendRequest<Record<string, never>, { success: boolean; message: string }>(
        `/auth/password-recovery/${email}`,
        'POST',
        {}
      );

      toast({
        title: r.message,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
    } catch (e) {
      toast({
        title: 'Could not send recovery email',
        description: (e as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }

    setSending(false);
  };

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: '12' }} px={{ base: '0', sm: '8' }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size={{ base: 'xs', md: 'sm', lg: 'lg' }}>Forgot your password</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'white' }}
              boxShadow={{ base: 'none', sm: 'md' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      onKeyDown={(e) => e.key === 'Enter' && sendRecoveryEmail()}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing="6">
                  <Button
                    bg="blue.500"
                    color="white"
                    onClick={sendRecoveryEmail}
                    isLoading={sending}
                    type="submit"
                  >
                    Send recovery email
                  </Button>
                  <Button bg="gray.300" color="black" onClick={() => navigate('/login')}>
                    Go back
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};
