import React from 'react';

interface ErrorFallbackProps {
  error: unknown;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error }) => {
  return (
    <div role="alert">
      <h2>Something went wrong</h2>
      <p>We're sorry, there was an unexpected error. Please try again later.</p>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {(error as Error).message && <p>{(error as Error).message}</p>}
      </details>
    </div>
  );
};
