import React, { useEffect, useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import { CountryCode } from 'utils/iso2.mapper';
import { BaseModalProps } from './interfaces';

interface NextCityModalProps extends BaseModalProps {
  points: number;
  seconds: number;
  location: string;
  countryCode: CountryCode;
  levelPoints: number;
  pointsToAdvance: number;
  locationIndex: number;
  totalLocations: number;
  distance: number;
  scorePerCity: number;
  goNextCity(): void;
  finishGame(): void;
}

export const NextCityModal: React.FC<NextCityModalProps> = ({
  open,
  points,
  location,
  pointsToAdvance,
  levelPoints,
  goNextCity,
  seconds,
  locationIndex,
  totalLocations,
  countryCode,
  distance,
  scorePerCity,
  finishGame,
}) => {
  const { isOpen: showEndDialog, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const ldRef = useRef(null);
  const cancelRef = useRef(null);

  const [isOpen, setIsOpen] = useState(open);
  const [score] = useState(points);
  const [nextLocationIndex] = useState(locationIndex + 1);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    const handleSpacePress = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        goNextCity();
      }
    };

    window.addEventListener('keydown', handleSpacePress);

    return () => {
      window.removeEventListener('keydown', handleSpacePress);
    };
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        isCentered
        autoFocus={false}
        onClose={() => onOpen()}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        size="xl"
        leastDestructiveRef={ldRef}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="bgLightBlue" p={8}>
          <AlertDialogHeader>
            <Box display="flex" alignItems="center">
              <Text pr={2}>Stats for {location}</Text>
              <ReactCountryFlag svg countryCode={countryCode} style={{ fontSize: '32px' }} />
            </Box>
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody pb={8} pt={4}>
            <Box borderWidth="1px" borderColor="gray.300" p={4} mb={8} borderRadius="md" flex={3}>
              <StatGroup>
                <Stat>
                  <StatLabel>Time</StatLabel>
                  <StatNumber>{seconds.toFixed(2)}s</StatNumber>
                </Stat>

                <Stat>
                  <StatLabel>Distance</StatLabel>
                  <StatNumber>{distance.toFixed(2)}km</StatNumber>
                </Stat>
              </StatGroup>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress
                value={(score / scorePerCity) * 100}
                color="green.400"
                thickness="10px"
                size="180px"
              >
                <CircularProgressLabel>
                  <Text fontSize="xl" fontWeight={800}>
                    {score.toFixed(1)}
                  </Text>
                  <Text fontSize="sm" fontWeight={400}>
                    CITY SCORE
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>

              <Box w={8} />

              <CircularProgress
                value={(levelPoints / pointsToAdvance) * 100}
                color="blue.400"
                thickness="10px"
                size="180px"
              >
                <CircularProgressLabel>
                  <Text fontSize="xl" fontWeight={800}>
                    {levelPoints.toFixed(1)}
                  </Text>
                  <Text fontSize="sm" fontWeight={400}>
                    LEVEL SCORE
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </AlertDialogBody>

          <VStack spacing={4}>
            <Box display="flex" justifyContent="center" w="full">
              <ButtonGroup spacing={4}>
                <Button bgColor="green.300" size="md" onClick={goNextCity}>
                  Next{' '}
                  {nextLocationIndex <= totalLocations &&
                    `${nextLocationIndex} / ${totalLocations}`}
                </Button>
              </ButtonGroup>
            </Box>
            <Text fontSize="md" textAlign="center">
              You can press{' '}
              <i>
                <b>space</b>
              </i>{' '}
              key for next location.
            </Text>
          </VStack>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={showEndDialog} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Finish Game
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to end the game?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  finishGame();
                  navigate('/hub');
                }}
                ml={3}
              >
                Finish Game
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
