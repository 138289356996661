import { Box, Container, Heading, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { globalContext } from 'context';
import { useContext, useEffect } from 'react';
import { HiUserAdd } from 'react-icons/hi';
import { LuLogIn } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export const AboutPage = () => {
  const navigate = useNavigate();

  const { state } = useContext(globalContext);

  useEffect(() => {
    document.title = 'About MapIQ - Discover Everything You Need to Know';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={state.isAuthenticated}
          buttons={
            state.isAuthenticated
              ? []
              : [
                  {
                    label: 'Sign Up',
                    onClick: () => navigate('/register'),
                    bgColor: 'green.300',
                    icon: <Icon h={5} w={5} as={HiUserAdd} />,
                  },
                  {
                    label: 'Login',
                    onClick: () => navigate('/login'),
                    bgColor: 'gray.300',
                    icon: <Icon h={5} w={5} as={LuLogIn} />,
                  },
                ]
          }
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: 4, sm: 8 }}>
          <Heading as="h1" size="xl" fontWeight={900} textAlign="center">
            About MapIQ
          </Heading>
        </Container>

        <Box
          mx={{ base: 0, sm: 8, lg: 16 }}
          mb={{ base: 8 }}
          px={{ base: 8 }}
          py={{ base: 8 }}
          bg={{ base: 'transparent', sm: 'white' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            MapIQ is a challenging and educational web game designed to test and improve your
            geography skills. The game presents a blind world map, and your task is to click as
            close as you can to a list of given locations. With various modes and increasing levels
            of difficulty, MapIQ offers an engaging way to learn about world geography.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            Game Modes:
          </Heading>
          <UnorderedList spacing={4} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} mb={8}>
            <ListItem>
              Classic: The main mode and the most challenging. It consists of 10 levels with
              increasing difficulty. You have 10 seconds to locate each city.
            </ListItem>
            <ListItem>
              Short: A bit easier, with only 5 levels. This mode features more well-known cities,
              giving you 10 seconds for each.
            </ListItem>
            <ListItem>
              Rapid: Also 10 levels, but you have only 5 seconds per city. This mode features easier
              cities compared to Classic Mode.
            </ListItem>
            <ListItem>
              Football Stadiums: Test your football knowledge with 5 levels focused on famous
              stadiums. You have 10 seconds to find each one.
            </ListItem>
            <ListItem>
              World Heritage Sites: Explore 5 levels featuring UNESCO World Heritage Sites. You have
              10 seconds to locate each historic landmark.
            </ListItem>
          </UnorderedList>
          <Heading as="h2" size="md" mb={4}>
            Scoring:
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            Your score is calculated based on the distance from the actual city location and the
            time taken to make your guess. The closer and faster you are, the higher your score. To
            advance through each level, you must achieve a predetermined number of points. At the
            end of the game, your performance is summarized with a MapIQ score.
          </Text>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500}>
            We are continually working to introduce new modes and features to keep the game
            exciting. Stay tuned for updates!
          </Text>
        </Box>
      </Box>
    </Layout>
  );
};
