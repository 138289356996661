export enum Routes {
  ABOUT = '/about',
  PLAY = '/play',
  DASHBOARD = '/dashboard',
  LEADERBOARD = '/leaderboard',
  LOGIN = '/login',
  LOGOUT = '/logout',
  REGISTER = '/register',
  HUB = '/hub',
  AUTH_CALLBACKS = '/auth-callbacks',
  AUTH_REDIRECT = '/auth-redirect',
}
