import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import ReactCountryFlag from 'react-country-flag';

interface Props {
  secondsPassed: number;
  totalPoints: number;
  levelPoints: number;
  toPass: number;
  locationLabel: string;
  countryCode: string;
  level: number;
  totalLevels: number;
  locationIndex: number;
  levelLocations: number;
  disabled: boolean;
  levelTime: number;
}

export const GameNavbar: React.FC<Props> = ({
  secondsPassed,
  disabled,
  toPass,
  level,
  totalLevels,
  totalPoints,
  levelPoints,
  locationIndex,
  levelLocations,
  locationLabel,
  countryCode,
  levelTime,
}) => {
  const width = (secondsPassed / levelTime) * 100;

  return (
    <Box
      bg={'gray.900'}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      h="80px"
      px={4}
      position="relative"
    >
      {disabled ? null : (
        <>
          <Box
            bgColor={width > 65 ? (width > 85 ? 'red.500' : 'orange.400') : 'green.300'}
            width={`${width}%`}
            transition="width 0.1s linear;"
            position="absolute"
            height="full"
            mx={-4}
          />
          <Flex flexDirection="column" justifyContent="space-between" zIndex={100}>
            <Text fontWeight={400} color="white">
              Total points: {totalPoints.toFixed(1)}
            </Text>
            <Text fontWeight={600} fontSize={20} color="white">
              Level points: {levelPoints.toFixed(1)}
            </Text>

            <Text fontWeight={400} color="white">
              To pass: {toPass}
            </Text>
          </Flex>

          <Box display="flex" alignItems="center" zIndex={100}>
            <Heading
              fontWeight={600}
              fontSize={{ base: 20, xl: 24, '2xl': 28 }}
              color="white"
              pr={4}
            >
              {locationLabel}
            </Heading>
            <ReactCountryFlag svg countryCode={countryCode} style={{ fontSize: '40px' }} />
          </Box>

          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems={'flex-end'}
            zIndex={100}
          >
            <Text fontWeight={400} color="white">
              Level {level + 1}/{totalLevels}
            </Text>
            <Text fontWeight={600} fontSize={20} color="white">
              Location {locationIndex === levelLocations ? locationIndex : locationIndex + 1}/
              {levelLocations}
            </Text>

            <Text fontWeight={400} color="white">
              Time left: {(levelTime - secondsPassed).toFixed(1)}s
            </Text>
          </Flex>
        </>
      )}
    </Box>
  );
};
