import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';

import { NavbarProps } from './interface';

import { HamburgerIcon } from '@chakra-ui/icons';
import logo from 'assets/logo.png';
import { globalContext } from 'context';
import { useContext } from 'react';

export const Navbar: React.FC<NavbarProps> = ({ buttons, showUserMenu = true }) => {
  const navigate = useNavigate();

  const { state } = useContext(globalContext);

  const screenSize = useBreakpointValue(['xs', 'sm', 'md', 'lg']);

  return (
    <Box
      bg="bgLightBlue"
      paddingX={10}
      paddingY={4}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" onClick={() => navigate('/')} cursor="pointer">
        <Image src={logo} alt="logo" h={16} w={16} />
        <Heading
          as="h1"
          fontWeight={900}
          color="gray.800"
          pl={4}
          display={{ base: 'none', sm: 'none', md: 'block' }}
        >
          MapIQ
        </Heading>
      </Box>

      <Flex alignItems={'center'}>
        <Stack direction={'row'} spacing={7}>
          {/* <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button> */}

          <ButtonGroup spacing={4}>
            {buttons?.map((b, i) => (
              <Button
                key={i}
                onClick={b.onClick}
                bgColor={b.bgColor}
                leftIcon={b.icon}
                size={screenSize}
              >
                {b.label}
              </Button>
            ))}
          </ButtonGroup>

          {showUserMenu && (
            <Menu>
              <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                <Avatar bg="blue.700" icon={<HamburgerIcon fontSize="1.5rem" />} />
              </MenuButton>
              <MenuList alignItems={'center'}>
                <Center>
                  <p>{state.user?.nickname}</p>
                </Center>
                <MenuDivider />
                <MenuGroup title="User">
                  <MenuItem onClick={() => navigate('/dashboard')}>Dashboard</MenuItem>
                  <MenuItem isDisabled onClick={() => navigate('/settings')}>
                    Account Settings
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />

                <MenuGroup title="Game">
                  <MenuItem onClick={() => navigate('/hub')}>Hub</MenuItem>
                  <MenuItem onClick={() => navigate('/leaderboard')}>Leadearboard</MenuItem>
                </MenuGroup>

                <MenuDivider />
                <MenuItem onClick={() => navigate('/logout')}>Logout</MenuItem>
              </MenuList>
            </Menu>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};
