import React, { useEffect, useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Input,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PatchNicknameDto, UserDto } from 'types/api/user.dto';
import { sendRequest } from 'utils/api';
import { BaseModalProps } from './interfaces';

interface UserDataModalProps extends BaseModalProps {
  userId: string;
}

export const UserDataModal: React.FC<UserDataModalProps> = ({ open, userId }) => {
  const navigate = useNavigate();
  const ldRef = useRef(null);

  const [isOpen, setIsOpen] = useState(open);
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleContinue = async () => {
    if (nickname.length === 0) {
      return;
    }

    const user = await sendRequest<PatchNicknameDto, UserDto>(
      `/users/${userId}`,
      'PATCH',
      { nickname },
      true
    );

    if (user) {
      navigate('/hub');
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered
      autoFocus={false}
      onClose={() => {
        setIsOpen(false);
        navigate('/profile');
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
      leastDestructiveRef={ldRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent bgColor="bgLightBlue" p={8}>
        <AlertDialogHeader>Welcome to MapIQ!</AlertDialogHeader>
        <AlertDialogBody pb={8} pt={4}>
          <Text fontSize={16} pb={8}>
            Add your nickname to continue.
          </Text>
          <Box display="flex" alignItems="center" justifyContent="center" pb={8}>
            <FormControl>
              <Input
                id="nickname"
                type="text"
                placeholder="GeoWizard42 (a-z, A-Z, 0-9)"
                borderColor="gray.500"
                value={nickname}
                onChange={(e) => setNickname(e.currentTarget.value)}
              />
            </FormControl>
          </Box>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Box display="flex" justifyContent="center" w="full">
            <ButtonGroup spacing={4}>
              <Button
                bgColor="green.300"
                size="md"
                onClick={handleContinue}
                isDisabled={nickname.length === 0}
              >
                Continue
              </Button>
            </ButtonGroup>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
