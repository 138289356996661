import { Box, Container, Heading, Icon, Text } from '@chakra-ui/react';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { globalContext } from 'context';
import { useContext, useEffect } from 'react';
import { HiUserAdd } from 'react-icons/hi';
import { LuLogIn } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  const { state } = useContext(globalContext);

  useEffect(() => {
    document.title = 'MapIQ Privacy Policy';
  }, []);

  return (
    <Layout
      navbar={
        <Navbar
          showUserMenu={state.isAuthenticated}
          buttons={
            state.isAuthenticated
              ? []
              : [
                  {
                    label: 'Sign Up',
                    onClick: () => navigate('/register'),
                    bgColor: 'green.300',
                    icon: <Icon h={5} w={5} as={HiUserAdd} />,
                  },
                  {
                    label: 'Login',
                    onClick: () => navigate('/login'),
                    bgColor: 'gray.300',
                    icon: <Icon h={5} w={5} as={LuLogIn} />,
                  },
                ]
          }
        />
      }
      showFooter
    >
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: 4, sm: 8 }}>
          <Heading as="h1" size="xl" fontWeight={900} textAlign="center">
            Privacy policy
          </Heading>
        </Container>

        <Box
          mx={{ base: 0, sm: 8, lg: 16 }}
          mb={{ base: 8 }}
          px={{ base: 8 }}
          py={{ base: 8 }}
          bg={{ base: 'transparent', sm: 'white' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Heading as="h2" size="md" mb={4}>
            1. Information We Collect
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            We collect information you provide when you create an account, such as your email
            address, and information from social logins (Google, Facebook). We also collect data on
            your game usage and performance. Guest users play anonymously and no personal data is
            collected from them.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            2. How We Use Information
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            We use your information to provide and improve our services, personalize your
            experience, and communicate with you. We may also use aggregated data for analytics
            purposes.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            3. Sharing Information
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            We do not share your personal information with third parties except as necessary to
            provide our services or as required by law.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            4. Data Security
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            We implement reasonable security measures to protect your information from unauthorized
            access, alteration, or disclosure.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            5. Your Choices
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            You may update your account information or request to delete your account, by sending a
            request at support@map-iq.com, at any time. You may also opt out of receiving
            promotional communications from us.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            6. Changes to Privacy Policy
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            Changes to Privacy Policy We may update this policy from time to time. We will notify
            you of any significant changes by posting the new policy on our site.
          </Text>
          <Heading as="h2" size="md" mb={4}>
            7. Contact Us
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md', md: 'xl' }} fontWeight={500} mb={8}>
            Contact Us If you have any questions or concerns about our privacy practices, please
            contact us at support@map-iq.com.
          </Text>
        </Box>
      </Box>
    </Layout>
  );
};
