import { Box, Button, Container, Heading, Stack, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { PasswordField } from 'components/ui/PasswordField';
import { useEffect, useState } from 'react';
import { NewPasswordDto } from 'types/api/user.dto';
import { sendRequest } from 'utils/api';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const toast = useToast();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [resettingPassword, setResettingPassword] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = 'MapIQ - Reset Your Password';
  }, []);

  const cleanErrors = () => {
    setPasswordError('');
    setRepeatPasswordError('');
  };

  const validateForm = (): boolean => {
    if (!/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(password)) {
      setPasswordError(
        'Password needs to be at least 8 characters long and contain 1 letter and 1 number'
      );
      return false;
    }

    if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords do not match');
      return false;
    }

    return true;
  };

  const resetPassword = async () => {
    cleanErrors();
    setResettingPassword(true);

    if (!validateForm()) {
      setResettingPassword(false);

      return;
    }

    const token = searchParams.get('token') as string;

    try {
      await sendRequest<NewPasswordDto, Record<string, never>>('/auth/reset-password', 'POST', {
        token,
        password,
      });
      navigate('/login');
    } catch (e) {
      toast({
        title: 'Password reset failed',
        description: (e as Error).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }

    setResettingPassword(false);
  };

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        <Container maxW="lg" py={{ base: '12' }} px={{ base: '0', sm: '8' }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size={{ base: 'xs', md: 'sm', lg: 'lg' }}>Reset password</Heading>
              </Stack>
            </Stack>
            <Box
              py={{ base: '0', sm: '8' }}
              px={{ base: '4', sm: '10' }}
              bg={{ base: 'transparent', sm: 'white' }}
              boxShadow={{ base: 'none', sm: 'md' }}
              borderRadius={{ base: 'none', sm: 'xl' }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <PasswordField
                    id="password"
                    placeholder="Password"
                    isRequired
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    error={passwordError}
                  />
                  <PasswordField
                    id="repeatPassword"
                    placeholder="Repeat password"
                    isRequired
                    onChange={(e) => setRepeatPassword(e.currentTarget.value)}
                    error={repeatPasswordError}
                    onKeyDown={(e) => e.key === 'Enter' && resetPassword()}
                  />
                </Stack>
                <Stack spacing="6">
                  <Button
                    bg="blue.500"
                    color="white"
                    onClick={resetPassword}
                    isLoading={resettingPassword}
                    type="submit"
                  >
                    Reset password
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};
