import { Box, Image, Spinner, keyframes } from "@chakra-ui/react";

import { ScreenPoint } from "types/Point";

import leftPin from "assets/left-pin.svg";
import rightPin from "assets/right-pin.svg";
import targetCursor from "assets/target.png";
import worldMap from "assets/world-map.png";

const drop = (to: number) => keyframes`
    from {
        transform: translateY(-40px)
    }
    to {
        transform: translateY(${to}px)
    }
`;

interface Props {
  cityLocation?: ScreenPoint;
  clickedLocation?: ScreenPoint;
  onClick: (point: ScreenPoint) => void;
  onMapLoaded: () => void;
}

export const WorldMap: React.FC<Props> = ({
  cityLocation,
  clickedLocation,
  onClick,
  onMapLoaded,
}) => {
  return (
    <Box
      h="calc(100vh - 80px)"
      cursor={`url('${targetCursor}'), auto`}
      onClick={(e) => onClick({ x: e.pageX, y: e.pageY })} // adjust for cursor offset
    >
      <Image
        src={worldMap}
        h="full"
        w="full"
        fallback={
          <Box
            h="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" />
          </Box>
        }
        onLoad={() => onMapLoaded()}
      />

      {clickedLocation ? (
        <Image
          src={rightPin}
          style={{
            position: "absolute",
            left: clickedLocation.x + 2,
            top: -40,
          }}
          w={10}
          h={10}
          animation={`${drop(clickedLocation.y + 14)} 1s ease forwards`}
        />
      ) : null}
      {cityLocation ? (
        <Image
          src={leftPin}
          style={{
            position: "absolute",
            left: cityLocation.x - 27,
            top: -40,
          }}
          w={10}
          h={10}
          animation={`${drop(cityLocation.y + 82)} 1s ease forwards`}
        />
      ) : null}
    </Box>
  );
};
