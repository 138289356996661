import { useBoolean } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GeoPoint, ScreenPoint } from 'types/Point';
import { GameDto } from 'types/api/game.dto';
import { CountryCode } from 'utils/iso2.mapper';
import { getScreenCoordinates } from 'utils/screen-geometry';

export const useGameInfo = (
  currentLevel: number,
  currentLocation: number,
  levelScore: number,
  game?: GameDto
) => {
  const [isLevelOver, setIsLevelOver] = useBoolean(false);
  const [isGameOver, setIsGameOver] = useBoolean(false);

  const [pointsToPass, setPointsToPass] = useState(0);
  const [levelLocations, setLevelLocations] = useState<number>(0);
  const [nextLevelPointsToPass, setNextLevelPointsToPass] = useState<number>(0);
  const [nextLevelLocations, setNextLevelLocations] = useState<number>(0);

  const [currentCityGeoLocation, setCurrentCityGeoLocation] = useState<GeoPoint>();

  const [scorePerCity, setScorePerCity] = useState<number>(0);
  const [cityName, setCityName] = useState<string>('');
  const [countryCode, setCountryCode] = useState<CountryCode>('AA');

  const [correctLocationPoint, setCorrectLocationPoint] = useState<ScreenPoint>();

  const levels = useMemo(() => (game ? game.config.levels : []), [game]);
  const totalLevels = useMemo(() => (game ? game.config.noLevels : 0), [game]);

  const getTotalCities = useCallback(
    (l: number = levels.length) => {
      let totalCities = 0;
      for (let i = 0; i < l && i < levels.length; i++) {
        totalCities += levels[i].cities.length;
      }

      return totalCities;
    },
    [levels]
  );

  const getCorrectLocationPoint = useCallback((): ScreenPoint => {
    const currentCityGeoPoint: GeoPoint = {
      lat: levels[currentLevel].cities[currentLocation].lat,
      long: levels[currentLevel].cities[currentLocation].long,
    };

    setCurrentCityGeoLocation(currentCityGeoPoint);

    const currentCityLocation = getScreenCoordinates(currentCityGeoPoint, {
      width: window.innerWidth,
      height: window.innerHeight - 80,
    });

    return currentCityLocation;
  }, [currentLevel, currentLocation, levels]);

  useEffect(() => {
    if (levelLocations > 0 && currentLocation === levelLocations) {
      if (levelScore < pointsToPass) {
        setIsGameOver.on();
        return;
      }
      setIsLevelOver.on();
    } else {
      setIsLevelOver.off();
    }
  }, [currentLocation, setIsLevelOver, levelLocations, levelScore, pointsToPass, setIsGameOver]);

  useEffect(() => {
    if (isLevelOver && (currentLevel >= totalLevels - 1 || levelScore < pointsToPass)) {
      setIsGameOver.on();
    }
  }, [currentLevel, setIsGameOver, levelScore, isLevelOver, pointsToPass, totalLevels]);

  useEffect(() => {
    if (levels.length > 0) {
      setPointsToPass(levels[currentLevel].scoreToAdvance);
      setLevelLocations(levels[currentLevel].cities.length);
      setScorePerCity(levels[currentLevel].scorePerCity);

      if (currentLocation < levels[currentLevel].cities.length) {
        setCityName(levels[currentLevel].cities[currentLocation].city);
        setCountryCode(levels[currentLevel].cities[currentLocation].iso2);
        setCorrectLocationPoint(getCorrectLocationPoint());
      }

      if (currentLevel < levels.length - 1) {
        setNextLevelPointsToPass(levels[currentLevel + 1].scoreToAdvance);
        setNextLevelLocations(levels[currentLevel + 1].cities.length);
      }
    }
  }, [currentLevel, currentLocation, levels, getCorrectLocationPoint, levelLocations]);

  return {
    correctLocationPoint,
    levelLocations,
    isLevelOver,
    isGameOver,
    pointsToPass,
    scorePerCity,
    cityName,
    countryCode,
    nextLevelPointsToPass,
    nextLevelLocations,
    currentCityGeoLocation,
    totalLevels,
    getTotalCities,
  };
};
