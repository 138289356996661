import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { AboutPage } from 'pages/AboutPage';
import { AuthCallbacksPage } from 'pages/AuthCallbacks';
import { AuthRedirectPage } from 'pages/AuthRedirectPage';
import { ForgotPasswordPage } from 'pages/ForgotPassword';
import { GamePage } from 'pages/GamePage';
import { HubPage } from 'pages/HubPage';
import { LeaderboardPage } from 'pages/LeaderboardPage';
import { LoginPage } from 'pages/LoginPage';
import { LogoutPage } from 'pages/LogoutPage';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import { ProfilePage } from 'pages/ProfilePage';
import { RegisterPage } from 'pages/RegisterPage';
import { ResetPasswordPage } from 'pages/ResetPassword';
import { TermsOfServicePage } from 'pages/TermsOfServicePage';
import { LandingPage } from './pages/LandingPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/play" element={<Navigate to="/hub" />} />
      <Route path="/play/:mode" element={<GamePage />} />
      <Route path="/dashboard" element={<ProfilePage />} />
      <Route path="/leaderboard" element={<LeaderboardPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/hub" element={<HubPage />} />
      <Route path="/auth-callbacks" element={<AuthCallbacksPage />} />
      <Route path="/auth-redirect" element={<AuthRedirectPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    </>
  )
);
