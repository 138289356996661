import Cookies from 'js-cookie';
import queryString from 'query-string';
import useSWR from 'swr';
import { TokenDto } from 'types/api/token.dto';

export const API_HOST = import.meta.env.VITE_API_HOST;

export const headers: HeadersInit = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/**
 * Generic function that handles CRUD on server resources.
 *
 * @remarks
 * It accepts two types D for body data type and R for response type
 *
 * @param url - Url of resource
 * @param method - POST | PATCH | PUT
 * @param data - Body data type of type D
 * @returns Response of type R
 *
 */
export const sendRequest = async <D, R>(
  url: string,
  method: 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  data: D,
  auth: boolean = true
): Promise<R> => {
  const response = await fetch(`${API_HOST}${url}`, {
    headers: getHeaders(auth),
    body: JSON.stringify(data),
    method: method,
    mode: 'cors',
  });

  return checkResponse(response);
};

export const postUrlEncoded = async <R>(url: string, data: Record<string, string>): Promise<R> => {
  const response = await fetch(`${API_HOST}${url}`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(data),
    method: 'POST',
  });

  return checkResponse(response);
};

export const getResource = async <R>(
  url: string,
  auth: boolean = false,
  params?: Record<string, string | undefined>
): Promise<R> => {
  const qp = queryString.stringify(params || {});

  const response = await fetch(`${API_HOST}${url}${qp.length > 0 ? `?${qp}` : ''}`, {
    headers: getHeaders(auth),
  });

  return checkResponse(response);
};

const checkResponse = async (response: Response) => {
  const json = await response.json();

  if (!response.ok) {
    throw new Error(json.detail);
  }

  return json;
};

export const getHeaders = (auth?: boolean, includeContentType: boolean = true): HeadersInit => {
  let h = {};
  if (includeContentType) {
    h = { ...headers };
  }

  if (auth) {
    const token = Cookies.get('token');
    h = { ...h, Authorization: `Bearer ${token}` };
  }

  return h;
};

export const fetcher = async <R>(url: string, auth: boolean = false): Promise<R> => {
  const response = await fetch(url, {
    headers: getHeaders(auth),
  });

  return checkResponse(response);
};

export const useDataResource = <R>(
  url: string,
  auth: boolean = false,
  params?: Record<string, string | undefined>
) => {
  const qp = queryString.stringify(params || {});

  const response = useSWR<R>(
    [`${API_HOST}${url}${qp.length > 0 ? `?${qp}` : ''}`, auth],
    ([url, auth]) => fetcher(url, auth as boolean),
    { shouldRetryOnError: false, dedupingInterval: Infinity }
  );

  return response;
};

export const postFormData = async <R>(url: string, data: FormData): Promise<R> => {
  const response = await fetch(`${API_HOST}${url}`, {
    headers: getHeaders(true),
    body: data,
    method: 'POST',
    redirect: 'follow',
  });

  return response.json();
};

export const getAuthToken = async (email: string, password: string): Promise<TokenDto> => {
  const params = new URLSearchParams();
  params.append('username', email);
  params.append('password', password);

  const response = await fetch(`${API_HOST}/auth/login`, {
    method: 'POST',
    body: params,
  });

  const json = await response.json();

  if (!response.ok) {
    const { detail: error } = json as { detail: string };

    throw new Error(error);
  }

  return json;
};
