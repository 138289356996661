import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from 'components/common/Fallback';
import { GlobalContextProvider } from 'context/Provider';
import { Shell } from 'modules/Shell';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { theme } from 'styles/theme';

interface Props {}

const Providers: React.FC<Props> = () => {
  return (
    <GlobalContextProvider>
      <Sentry.ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
        <ChakraProvider theme={theme}>
          <Shell>
            <RouterProvider router={router} />
          </Shell>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </GlobalContextProvider>
  );
};

export default Providers;
