import React, { useEffect, useRef, useState } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/enums/routes.enum';
import { BaseModalProps } from './interfaces';

export const SmallScreenModal: React.FC<BaseModalProps> = ({ open }) => {
  const navigate = useNavigate();
  const ldRef = useRef(null);

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <AlertDialog
      isOpen={isOpen}
      isCentered
      autoFocus={false}
      onClose={() => {
        setIsOpen(false);
        navigate(Routes.DASHBOARD);
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
      leastDestructiveRef={ldRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent bgColor="bgLightBlue" p={8}>
        <AlertDialogHeader>
          Game on small screen is not supported since map becomes too small to play.
        </AlertDialogHeader>
        <AlertDialogBody pb={8} pt={4}></AlertDialogBody>

        <AlertDialogFooter>
          <Box display="flex" justifyContent="center" w="full">
            <ButtonGroup spacing={4}>
              <Button bgColor="green.300" size="md" onClick={() => navigate(Routes.DASHBOARD)}>
                Go to profile
              </Button>
            </ButtonGroup>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
