import { Box, Container, Spinner, useBoolean } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/layout/Layout';
import { Navbar } from 'components/layout/Navbar';
import { UserDataModal } from 'components/ui/modals/UserDataModal';
import { globalContext } from 'context';
import { useContext, useEffect } from 'react';
import { UserDto } from 'types/api/user.dto';
import { useDataResource } from 'utils/api';

export const AuthRedirectPage = () => {
  const navigate = useNavigate();

  const { dispatch } = useContext(globalContext);

  const { data: user, isLoading } = useDataResource<UserDto>('/users/me', true);

  const [userDataModal, setUserDataModal] = useBoolean();

  useEffect(() => {
    if (user) {
      dispatch({ type: 'set-user', payload: user });

      if (user.nickname) {
        navigate('/hub');
      } else {
        setUserDataModal.on();
      }
    }
  }, [user, navigate, setUserDataModal, dispatch]);

  return (
    <Layout navbar={<Navbar showUserMenu={false} />} showFooter>
      <Box w="full" h="full" bg="bgLightBlue">
        {isLoading ? (
          <Container centerContent h="full" alignItems="center" justifyContent="center">
            <b color="white">bla</b>
            <Spinner size="xl" color="black" />
          </Container>
        ) : (
          user && <UserDataModal open={userDataModal} userId={user.id} />
        )}
      </Box>
    </Layout>
  );
};
